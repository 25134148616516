<template>
  <div class="w-full">
    <sign-file-loader v-if="isSystemValid || !!tasks['load-plugin']" />
    <sign-no-plugin v-else />
  </div>
</template>

<script setup lang="ts">
import {EsButton} from "@esigndoc/ui";
import {$isSystemValid, $tasks} from "@/stores/app-store.ts";
import SignFileLoader from "@/components/index/sign-file-loader.vue";
import SignNoPlugin from "@/components/index/sign-no-plugin.vue";
import {useStore} from "@nanostores/vue";

const isSystemValid = useStore($isSystemValid)
const tasks = useStore($tasks)

</script>

<style>
#cadesplugin_ovr {
  z-index: 9999
}

#cadesplugin_ovr_item {
  border-radius: 4px;
}
#cadesplugin_ovr_item a {
  color: dodgerblue;
  cursor: pointer;
}

#cadesplugin_ovr_item a:hover {
  text-decoration: underline;
}
</style>

<template>
  <es-file-loader v-model="files" is-multiple :disabled="disabled" :on-before-file-push="upgradeFile" class="w-full">
    <template #label::append="{ file }: { file: EsFile }">
      <es-icon v-if="file.isSigned" icon="file-certificate-outline" class="text-accent-primary h-4 w-4" title="Файл подписан" />
    </template>
    <template #header>
      <es-button class="text-nowrap gap-2 !w-fit h-fit bg-accent-primary/[.1] px-2"
                 :disabled="!!tasks['sign']" is-active
                 @click="onSignAll"
      >
        Подписать все
      </es-button>
    </template>
    <template #label="{ file }: { file: EsFile }">
      <span class="ym-hide-content overflow-ellipsis overflow-x-hidden">{{ file.name }}</span>
    </template>
    <template #actions::prepend="{ file }: { file: EsFile }">
      <es-button v-if="file.isSigned"
                 class="h-7 w-7"
                 :disabled="!!tasks['sign']"
                 title="Скачать файл"
                 @click="onDownloadSingle(file)"
      >
        <es-icon :icon="iMonitorArrowDownVariant" />
      </es-button>
      <es-button v-if="file.type === 'application/pdf' && file.isSigned"
                 class="h-7 w-7"
                 :disabled="!!tasks['sign']"
                 title="Открыть для просмотра"
                 @click="openVisualSignatureModal(file, true)"
      >
        <es-icon :icon="iTextBoxSearchOutline" />
      </es-button>
        <es-button v-if="!file.isSigned"
                   class="h-7 w-7"
                   :disabled="!!tasks['sign']"
                   title="Создать открепленную подпись"
                   @click.stop="onSignSingle(file)"
        >
          <es-icon :icon="iSignatureFreehand" />
        </es-button>
        <es-button v-if="file.type === 'application/pdf' && !file.isSigned"
                   class="h-7 w-7"
                   :disabled="!!tasks['sign']"
                   title="Открыть для визуального подписания"
                   @click.stop="openVisualSignatureModal(file)"
        >
          <es-icon :icon="iFileSign" />
        </es-button>
    </template>
  </es-file-loader>
</template>

<script setup lang="ts">
import {EsFileLoader, EsIcon, EsButton, download, VisibleError} from "@esigndoc/ui";
import {useStore, useVModel} from "@nanostores/vue";
import {
  $certificate,
  $files, $isSystemValid,
  $isViewerOpened,
  $isViewerViewOnly,
  $selectedFile,
  $tasks, comment, getValidCertificateOptions,
  onDownloadSingle, onUpgradeSignature
} from "@/stores/app-store.ts";
import {EsFile} from "@/entities/es-file.ts";
import {useLazyStore} from "@/utils/lazy-store.ts";
import {
iFileSign,
iMonitorArrowDownVariant, iSignatureFreehand,
iTextBoxSearchOutline
} from "@/entities/icons.ts";
import {EsTask} from "@/entities/es-task.ts";
import JSZip from "jszip";
import {swapFileInArray} from "@/utils/swap-file-in-array.ts";
import {computed, nextTick} from "vue";

const files = useVModel($files)
const tasks = useStore($tasks)
const certificate = useLazyStore($certificate, null)
const isSystemValid = useStore($isSystemValid)
const disabled = computed(() => !!tasks.value['sign'] || !!tasks.value['load-plugin'] || !isSystemValid.value)

const upgradeFile = (_: File) => {
  return EsFile.upgrade(_)
}

const openVisualSignatureModal = async (file: EsFile, viewOnly = false) => {
  await $tasks.get()['load-plugin']?.lock.promise


  $isViewerOpened.set(true)
  $isViewerViewOnly.set(viewOnly)
  $selectedFile.set(file)
  if (!$certificate.value) {
    $certificate.set((await getValidCertificateOptions())[0])
    await nextTick()
    await $tasks.get()['generate-image']?.lock.promise
  }
}

const onSignAll = async () => {
  if (!$files.get().length) {
    return
  }

  const zip = new JSZip()

  $tasks.setKey('sign', new EsTask('Подписание всех файлов'))
  const signedFiles: EsFile[] = new Array($files.get().length)

  for (let i = 0; i < $files.get().length; i++) {
    const _ = $files.get()[i]
    if (_.isSigned) {
      zip.file(_.name, _, { date: new Date(_.lastModified), comment })
      signedFiles[i] = _
    } else {
      const { file, sig } = await _signFile(_) || {}
      signedFiles[i] = _

      zip.file(file.name, file, { date: new Date(file.lastModified) })
      zip.file(`${file.name}.sig`, sig, { comment })
    }
  }

  $files.set(signedFiles)
  const _ = await zip.generateAsync({ type: 'blob', comment })

  $tasks.setKey('sign', undefined)
  download(new File([_], `esigndoc-archive-${new Date().getTime()}.zip`))
}

const onSignSingle = async (file: EsFile) => {
  if (file.isSigned) {
    download(file)
    return
  }
  $tasks.setKey('sign', new EsTask(`Создание открепленной подписи для файла: ${file.name}`))

  try {
    const {file: _} = await _signFile(file)
    $files.set(swapFileInArray($files.get(), file))
    await onDownloadSingle(_)
  } finally {
    $tasks.setKey('sign', undefined)
  }
}

const _signFile = async(file: EsFile) => {
  const { getCertificate, createDetachedSignature, createHash } = await import("@esigndoc/dss")

  if (!$certificate.value) {
    $certificate.set((await getValidCertificateOptions())[0])
  }

  try {
    const { thumbprint } = await getCertificate($certificate.value!.id)
    const hash = await createHash(await file.arrayBuffer())
    const _sig = await createDetachedSignature(thumbprint, hash)

    const sig = await onUpgradeSignature(_sig)

    file.detachedSignature = sig
    file.isSigned = true
    return {file, sig}
  } catch (e) {
    throw new VisibleError('Ошибка подписания', e instanceof Error ? ` ${e.message}` : undefined)
  }
}

</script>


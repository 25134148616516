<template>
  <es-sheet class="flex gap-4 !p-6">
      <es-logo />
      <div class="flex flex-col gap-1">
        <strong class="text-md">Не настроен криптоплагин</strong>
        <a :href="usePluginInstructionGetter()" target="_blank" class="text-accent-primary text-sm hover:underline cursor-pointer">инструкция по установке CAdES Browser Plug-In</a>
      </div>
  </es-sheet>
</template>

<script setup lang="ts">
import {EsSheet, EsLogo} from "@esigndoc/ui";
import {usePluginInstructionGetter} from "@/utils/instruction-getter.ts";

</script>
